import { API_GATEWAY } from "@/services/config/main-axios";

class ReportService {
  getDepositTransactions(filters) {
    return API_GATEWAY.get("reporter/merchant/transaction/deposit", {
      params: filters,
    });
  }

  getDepositTrxById(id) {
    return API_GATEWAY.get(`reporter/merchant/transaction/deposit/${id}`);
  }

  getWithdrawTransactions(filters) {
    return API_GATEWAY.get("reporter/merchant/transaction/withdraw", {
      params: filters,
    });
  }

  getWithdrawTrxById(id) {
    return API_GATEWAY.get(`reporter/merchant/transaction/withdraw/${id}`);
  }

  getRefundTransactions(filters) {
    return API_GATEWAY.get("reporter/merchant/transaction/refund", {
      params: filters,
    });
  }

  getRefundTrxById(id) {
    return API_GATEWAY.get(`reporter/merchant/transaction/refund/${id}`);
  }
}

export default new ReportService();
