import { API_GATEWAY } from "@/services/config/main-axios";

class AccountService {
  getAllAccounts(filters) {
    return API_GATEWAY.get("user-account/account", {
      params: filters,
    });
  }

  getAllAccountTitles() {
    return API_GATEWAY.get("user-account/account/getAllAccountTitle");
  }

  getAccountByIsoAndNetwork(iso, network) {
    return API_GATEWAY.get(`user-account/account/${network}/${iso}`);
  }

  withdraw(model) {
    return API_GATEWAY.post("user-account/order/withdraw", model);
  }

  getWithdrawDetails(trxId) {
    return API_GATEWAY.get(`user-account/order/withdraw/detail/${trxId}`);
  }

  getWalletRegex(network) {
    return API_GATEWAY.get(`user-account/order/wallet-regex/${network}`);
  }

  checkTransaction(model) {
    return API_GATEWAY.post("user-account/transaction/checkTransaction", model);
  }

  checkNetworkStatus(model) {
    return API_GATEWAY.post("user-account/order", model);
  }

  acceptTransaction(model) {
    return API_GATEWAY.post("user-account/order/acceptTransaction", model);
  }

  refundTransaction(model) {
    return API_GATEWAY.post("user-account/order/refundTransaction", model);
  }
}

export default new AccountService();
