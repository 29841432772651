import { API_GATEWAY } from "@/services/config/auth-axios";

class AuthService {
  getAccessToken() {
    return localStorage.getItem("access_token");
  }

  passwordRegex() {
    return API_GATEWAY.get("user-profile/userInfo/register/rules");
  }

  register(model) {
    return API_GATEWAY.post("user-profile/userInfo/register", model);
  }

  otpInfo(otpToken) {
    return API_GATEWAY.get(`user-profile/otp/info/${otpToken}`);
  }

  resendOtp(otpToken) {
    return API_GATEWAY.get(`user-profile/otp/resend/${otpToken}`);
  }

  forgetResendOtp(otpToken) {
    return API_GATEWAY.get(`user-profile/otp/forgetResend/${otpToken}`);
  }

  completeRegister(model) {
    return API_GATEWAY.post("user-profile/userInfo/complete", model);
  }

  login(model) {
    return API_GATEWAY.post("user-profile/userInfo/login", model);
  }

  forgetPassword(model) {
    return API_GATEWAY.post("user-profile/userInfo/forgetPassword", model);
  }

  forgetPasswordOtp(model) {
    return API_GATEWAY.post("user-profile/userInfo/forgetPasswordOtp", model);
  }

  resetPassword(model) {
    return API_GATEWAY.post("user-profile/userInfo/resetPassword", model);
  }

  changePassword(model) {
    return API_GATEWAY.post("user-profile/userInfo/changePassword", model);
  }

  getCaptcha() {
    return API_GATEWAY.get("user-profile/captcha/captchaImg");
  }
}

export default new AuthService();
