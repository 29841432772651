import { API_GATEWAY } from "@/services/config/main-axios";

class MerchantService {
  getAllMerchants(filters) {
    return API_GATEWAY.get("user-profile/merchant", {
      params: filters,
    });
  }

  getMerchantNames() {
    return API_GATEWAY.get("user-profile/merchant/merchantsName");
  }

  getMerchantById(id) {
    return API_GATEWAY.get(`user-profile/merchant/${id}`);
  }

  addMerchant(model) {
    return API_GATEWAY.post("user-profile/merchant/add", model, {
      headers: {
        Accept: "*/*",
      },
    });
  }

  updateMerchant(model) {
    return API_GATEWAY.post("user-profile/merchant/update", model, {
      headers: {
        Accept: "*/*",
      },
    });
  }

  downloadMerchantLogo(fileName) {
    return API_GATEWAY.get(`user-profile/merchant/downloadImage/${fileName}`);
  }

  getMerchantTypes() {
    return API_GATEWAY.get("user-profile/business-type");
  }

  addGateway(model) {
    return API_GATEWAY.post("user-profile/gateway/add", model);
  }

  updateGatewayCurrencies(model) {
    return API_GATEWAY.post("user-profile/gateway/update/currencies", model);
  }

  getGatewayById(id) {
    return API_GATEWAY.get(`user-profile/gateway/${id}`);
  }

  getGatewaySettingsById(id) {
    return API_GATEWAY.get(`user-profile/setting/${id}`);
  }

  getGatewayTypes() {
    return API_GATEWAY.get("user-profile/gateway/gateway-types");
  }

  changeGatewayStatus(model) {
    return API_GATEWAY.post("user-profile/gateway/changeStatus", model);
  }
}

export default new MerchantService();
