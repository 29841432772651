import { API_GATEWAY } from "@/services/config/main-axios";

class AccountService {
  getUserInfo() {
    return API_GATEWAY.get("user-profile/userInfo");
  }

  updateUserInfo(model) {
    return API_GATEWAY.post("user-profile/userInfo/edit", model);
  }

  changePassword(model) {
    return API_GATEWAY.post("user-profile/userInfo/changePassword", model);
  }

  logout(model) {
    return API_GATEWAY.post("user-profile/userInfo/oauth/logout", model);
  }
}

export default new AccountService();
