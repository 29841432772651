import axios from "axios";
import Vue from "vue";
import { AuthService } from "@/services";
import i18n from "@/plugins/i18n";

const headers = {
  "content-type": "application/json",
  Accept: "application/json",
  "Accept-Language": "en",
};

const API_GATEWAY = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_GATEWAY,
  headers,
});

API_GATEWAY.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = i18n.locale;
    const token = AuthService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API_GATEWAY.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      await localStorage.setItem("isAuth", "false");
      await localStorage.removeItem("access_token");
      await localStorage.removeItem("refresh_token");
      window.location = `${process.env.VUE_APP_BASE_URL}/auth/login`;
    }

    if (error && error.response.config.method !== "get") {
      Vue.$toast.clear();
      if (error.response.data.error) {
        Vue.$toast.error(error.response.data.message);
      }
    }

    return Promise.reject(error.response);
  }
);

export { API_GATEWAY };
