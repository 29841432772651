import { API_GATEWAY } from "@/services/config/main-axios";

class CurrenciesService {
  getSupportedCurrencies() {
    return API_GATEWAY.get(
      "pricing/cryptoCurrency/getAllSupportedCryptoCurrency"
    );
  }

  getDefaultCommission() {
    return API_GATEWAY.get("pricing/commission/getDefaultCommission");
  }

  // getAllCurrencies(gatewayId) {
  //   return API_GATEWAY.post(
  //     `user-profile/admin/gateway/supportCurrency/${gatewayId}`
  //   );
  // }
}

export default new CurrenciesService();
